<ng-template #licenseRequired>
  @if (displayService.showLicenseRequired()) {
    <span>
      <a href="#" class="madero-link" (click)="confirmLicensing()" [shown]="userStateService.hasRole('da')">License Required</a>
      <span [shown]="!userStateService.hasRole('da')">License Required</span>
    </span>
  }
</ng-template>

<!-- DISPLAY ERRORS AND ALERTS -->
<div [shown]="playerActionsService.controlsError" class="madero-style alert alert-danger tw-mt-4" role="alert">
  <strong>{{playerActionsService.controlsError}}</strong>
</div>
<div class="fields-preview-panels display-fields-body tw-flex tw-h-full">
  <div class="preview-container tw-flex tw-flex-col tw-items-center tw-flex-[2] tw-overflow-y-auto tw-bg-gray-100">
    <div class="screenshot-embed tw-w-full tw-max-w-[768px] tw-px-6 xl:!tw-px-0">
      <screenshot (licenseRequired)="confirmLicensing()"></screenshot>
      <div>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <activation-notice></activation-notice>
          <div class="tw-flex tw-flex-row tw-items-end tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-pb-8">
            <label class="pull-left mb-3" [shown]="!userStateService.hasRole('cp')">Default Schedule: <span class="font-weight-normal">{{displayService.display.scheduleName || 'None'}}</span></label>
            <div class="tw-flex tw-flex-row tw-items-center tw-w-full">
              <preview-selector class="flex-grow" [(selectedItem)]="selectedSchedule" (selectedItemChange)="scheduleSelected()" label="Default Schedule" additionalClass="tw-flex tw-flex-row tw-mb-0 tw-items-center tw-justify-between" placement="bottom left" [shown]="userStateService.hasRole('cp')"></preview-selector>
              @if (selectedSchedule) {
                <a id="viewSchedule" class="tw-ml-6 tw-border-solid tw-border-gray-200 tw-rounded-full tw-px-2 tw-pt-2 tw-text-gray-700 tooltip-container" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: selectedSchedule.id, cid: selectedSchedule.companyId }">
                  <mat-icon svgIcon="edit-square" class="small dark" />
                  <div class="tw-relative tw-inline-block tw-cursor-pointer">
                    <div class="tooltip tw-absolute tw-bg-slate-800 tw-text-white tw-min-w-20 tw-rounded-md tw-text-center tw-p-4 tw-mb-4 tw-bottom-full tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-z-10">
                      Edit
                    </div>
                  </div>
                </a>
              }
            </div>
          </div>
          @if (displayService.hasOverrideSchedule(displayService.display)) {
            <div class="form-group tw-mb-0 tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-pb-8">
              <label class="tw-m-0">Override Schedule{{ displayService.display.overrideScheduleIds.length > 1 ? 's' : '' }}</label>
              <div class="tw-relative tw-inline-block tw-cursor-pointer tooltip-container">
                <mat-icon svgIcon="help" class="tw-text-gray-700 tw-ml-2" />
                <div class="tooltip tw-absolute tw-bg-slate-800 tw-text-white tw-min-w-72 tw-rounded-md tw-text-center tw-p-4 tw-bottom-full tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-z-10">
                  You can add more schedules to this display. 
                  <a href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Vision-Player-in-a-web-browser-" target="_blank" class="madero-link">Learn more</a>
                </div>
              </div>
              @for (override of displayService.display.overrideSchedules; track override) {
                <div class="tw-flex tw-flex-row tw-mb-2">
                  @if (override) {
                    <div class="tw-flex tw-flex-row tw-items-end tw-w-full tw-items-center">
                      <div class="tw-flex-grow u_ellipsis">
                        {{ override.name }}
                      </div>
                      <div [class]="override.scheduleTypeName === 'Replace' ? 
                        'tw-text-yellow-700 tw-bg-yellow-100 tw-px-4 tw-py-2 tw-rounded-full tw-mx-4 tw-flex tw-items-center tw-gap-2' : 
                        override.scheduleTypeName === 'Include' ?
                        'tw-text-blue-700 tw-bg-blue-100 tw-px-4 tw-py-2 tw-rounded-full tw-mx-4 tw-flex tw-items-center tw-gap-2' :
                        ''">
                        @if (override.scheduleTypeName === 'Replace') {
                          <mat-icon svgIcon="switch" class="small tw-text-yellow-700" />
                        }
                        @if (override.scheduleTypeName === 'Include') {
                          <mat-icon svgIcon="check" class="small tw-text-blue-700" />
                        }
                        {{ override.scheduleTypeName }}
                      </div>
                      @if (override.companyId !== companyStateService.getSelectedCompanyId()) {
                        <span class="u_link-disabled">
                          <mat-icon svgIcon="edit-square" class="small dark" />
                        </span>
                      }
                      @else {
                        <a class="tw-ml-6 tw-border-solid tw-border-gray-200 tw-rounded-full tw-px-2 tw-pt-2 tw-text-gray-700 tooltip-container" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: override.id, cid: companyStateService.getSelectedCompanyId() }">
                          <mat-icon svgIcon="edit-square" class="small dark" />
                          <div class="tw-relative tw-inline-block tw-cursor-pointer">
                            <div class="tooltip tw-absolute tw-bg-slate-800 tw-text-white tw-min-w-20 tw-rounded-md tw-text-center tw-p-4 tw-mb-4 tw-bottom-full tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-z-10">
                              Edit
                            </div>
                          </div>
                        </a>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
        <div class="tw-flex tw-justify-between tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-py-8">
          <label class="tw-mb-0">Last Connected</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          @if (!displayService.showLicenseRequired()) {
            <span>
              {{displayService.display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
            </span>
          }
        </div>
        <div class="tw-flex tw-justify-between tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-py-8" [hidden]="displayService.display.onlineStatus === 'not_activated'">
          <label class="tw-mb-0">Status</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          @if (!displayService.showLicenseRequired()) {
            <span class="status">
              <span class="text-gray" [shown]="displayService.display.onlineStatus === 'offline'">Offline</span>
              <span class="text-success" [shown]="displayService.display.onlineStatus === 'online'">Online</span>
            </span>
          }
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-py-8">
          <label>Display ID</label>
          <div class="display-info">
            <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <div>{{ displayService.display?.id }}</div>
              <a href="#" class="tw-ml-6 tw-border-solid tw-border-gray-200 tw-rounded-full tw-px-2 tw-pt-2 tw-text-gray-700 tooltip-container" (click)="copyToClipboard(displayService.display.id)">
                <mat-icon svgIcon="copy" class="small" />
                <div class="tw-relative tw-inline-block tw-cursor-pointer">
                  <div class="tooltip tw-absolute tw-bg-slate-800 tw-text-white tw-min-w-20 tw-rounded-md tw-text-center tw-p-4 tw-mb-4 tw-bottom-full tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-z-10">
                    Copy
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-py-8">
          <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <label class="tw-mb-0">Web Player Link</label>
              <div class="tw-relative tw-inline-block tw-cursor-pointer tooltip-container">
                <mat-icon svgIcon="help" class="tw-text-gray-700" />
                <div class="tooltip tw-absolute tw-bg-slate-800 tw-text-white tw-min-w-72 tw-rounded-md tw-text-center tw-p-4 tw-bottom-full tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-z-10">
                  Rise Vision Player can be run on any device with a web browser.
                  <a href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Vision-Player-in-a-web-browser-" target="_blank" class="madero-link">Learn more</a>
                </div>
              </div>
            </div>
              <a href="#" class="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-text-gray-700 tw-no-underline" (click)="showWebPlayer=!showWebPlayer">
                {{ showWebPlayer ? 'Hide' : 'Show' }}
                <mat-icon svgIcon="chevron-down" class="tw-ml-1" [class.tw-rotate-180]="showWebPlayer" />
              </a>
          </div>
          <span [shown]="showWebPlayer">
            <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-mt-2">
              <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2 tw-bg-gray-50 tw-p-4 tw-w-full">
                <span class="tw-flex-grow tw-truncate">{{ getWebPlayerUrl() }}</span>
                <a href="#" class="tw-ml-2 tw-text-gray-700 tooltip-container" (click)="copyToClipboard(getWebPlayerUrl())">
                  <mat-icon svgIcon="copy" class="small" />
                  <div class="tw-relative tw-inline-block tw-cursor-pointer">
                    <div class="tooltip tw-absolute tw-bg-slate-800 tw-text-white tw-min-w-20 tw-rounded-md tw-text-center tw-p-4 tw-mb-4 tw-bottom-full tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-z-10">
                      Copy
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-py-12 tw-bg-gray-50">
      <span>Don't have a Media Player? <a target="_blank" href="https://help.risevision.com/hc/en-us/articles/115001817143-What-is-a-Media-Player-get-one-pre-configured-build-your-own-?_ga=2.13275188.2099187533.1636965414-1754393580.1635242387">Find a Media Player</a> </span>
      <span>Want to Preview your Display? <a target="_blank" href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Vision-Player-in-a-web-browser-">Set Up the Web Player</a></span>
    </div>
  </div>
  <mat-tab-group #TabGroup [selectedIndex]="selectedTabIndex" class="display-tabs tw-border-0">
    <mat-tab label="General">
      <div class="fields-container !tw-w-full">
        @if (playerProService.isAndroidPlayer(displayService.display)) {
          <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-start tw-w-full">
            <div>
              <mat-icon svgIcon="alert-triangle" class="large"></mat-icon>
            </div>
            <div class="options tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div class="option-item">
                <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                  <label class="tw-mb-0">Override Alerts</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired() && isClassroomAlertsAvailable) {
                    <mat-slide-toggle name="toggleOverrideTakeover" [(ngModel)]="displayService.display.overrideTakeoverAllowed" [disabled]="displayService.showLicenseRequired()" id="overrideTakeoverAllowed" />
                  }
                  @if (!displayService.showLicenseRequired() && !isClassroomAlertsAvailable) {
                    <a href="#" class="madero-link" (click)="promptUpgrade('classroom_alerts')">Plan Upgrade Required</a>
                  }
                </div>
                <div class="info text-muted tw-mt-2">Allow Rise Player to take over the screen with overrides replace type schedules.</div>
              </div>
            </div>
          </div>
        }
        <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-start">
          <div>
            <mat-icon svgIcon="award" class="large"></mat-icon>
          </div>
          <div class="options tw-w-full tw-flex tw-flex-col tw-gap-4">
            <div class="option-item">
              <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                <label for="digitalSignageLicense" class="tw-mb-0">License</label>
                <mat-slide-toggle
                  id="digitalSignageLicense"
                  name="toggleLicense"
                  [(ngModel)]="displayService.display.playerProAuthorized"
                  (change)="displayService.toggleProAuthorized()"
                  [disabled]="!playerLicenseService.isProToggleEnabled(displayService.display)" />
              </div>
              <div class="info text-muted tw-mt-2 tw-flex tw-flex-row">
                <span class="tw-min-w-36">Licenses left:&nbsp;</span>
                <display-license-counts [countOnly]="true" />
              </div>
            </div>
            @if (displayService.display.isFreeForURLs) {
              <div class="option-item tw-flex tw-flex-row">
                <div class="row-entry">
                  <label class="control-label pull-left tw-mb-0"></label>
                  This is a free display.
                </div>
              </div>
            }
          </div>
        </div>
        <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-center"
          [shown]="shouldShowRotationOption">
          <div>
            <mat-icon svgIcon="phone-rotate" class="large"></mat-icon>
          </div>
          <div class="options tw-w-full tw-flex tw-flex-col tw-gap-4">
            <div class="option-item">
              <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4">
                <label class="tw-mb-0" for="orientation">Rotation</label>
                <select #orientation name="orientation" class="form-control" [(ngModel)]="displayService.display.orientation">
                  <option [ngValue]="0">Landscape</option>
                  <option [ngValue]="90">Portrait</option>
                  <option [ngValue]="180">Landscape (flipped)</option>
                  <option [ngValue]="270">Portrait (flipped)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-start">
          <div>
            <mat-icon svgIcon="map-pin" class="large"></mat-icon>
          </div>
          <div class="options tw-w-full tw-flex tw-flex-col tw-gap-4">
            <div class="option-item">
              <div class="control">
                <label class="tw-mb-0">Location</label>
              </div>
            </div>
            <div class="option-item">
              <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                <label id="useCompanyAddressLabel" class="tw-mb-0">Use Company Address</label>
                <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                @if (!displayService.showLicenseRequired()) {
                  <mat-slide-toggle
                    aria-labelledby="useCompanyAddressLabel"
                    name="toggleUseCompanyAddress"
                    [(ngModel)]="displayService.display.useCompanyAddress"
                    [disabled]="displayService.showLicenseRequired()"
                    id="useCompanyAddress" />
                }
              </div>
              <div class="info text-muted tw-mt-2">This will be used for address specific templates like weather and news.</div>
              <div class="mt-2" [shown]="!displayService.display.useCompanyAddress">
                <display-address [(display)]="displayService.display"></display-address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Manage">
      <div class="field-container w-100">
        @if (playerProService.isDisplayControlCompatiblePlayer(displayService.display)) {
          <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-start">
            <div>
              <mat-icon svgIcon="alarm-clock" class="large"></mat-icon>
            </div>
            <div class="options tw-w-full tw-flex tw-flex-col tw-gap-3">
              <div class="option-item">
                <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                  <label id="displayControlLabel" for="toggleDisplayControl" class="tw-mb-0">Display Control:</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired() && isMonitoringAvailable) {
                    <mat-slide-toggle
                      aria-labelledby="displayControlLabel"
                      name="toggleDisplayControl"
                      [(ngModel)]="displayService.display.isDisplayControlEnabled"
                      (change)="toggleDisplayControl()"
                      [disabled]="displayService.showLicenseRequired() || !userStateService.hasRole('da')"
                      id="toggleDisplayControl"/>
                  }
                  @if (!displayService.showLicenseRequired() && !isDisplayControlAvailable) {
                    <a href="#" class="madero-link" (click)="promptUpgrade('display_control')">Plan Upgrade Required</a>
                  }
                </div>
                <div class="info text-muted tw-mt-2" [shown]="!displayService.showLicenseRequired()">
                  Turn screen ON/OFF based on schedule.
                </div>
              </div>
              @if (playerProService.isGiadaRecommendedProduct(this.displayService.display) && displayService.display.isDisplayControlEnabled) {
                <div class="option-item">
                  <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                    <label id="modes-radio-group-label" class="tw-mb-0">Modes</label>
                    <div class="tw-flex tw-flex-row tw-row-wrap tw-gap-4 tw-items-center">
                      <mat-radio-group
                        aria-labelledby="modes-radio-group-label"
                        [(ngModel)]="displayControlType"
                        name="modes-radio-group"
                        (ngModelChange)="detectChanges()">
                        <mat-radio-button id="hdmi-cec" name="hdmiCec" [value]="'hdmi-cec'">HDMI-CEC</mat-radio-button>
                        <mat-radio-button id="rs-232" name="rs232" [value]="'rs-232'">RS-232</mat-radio-button>
                      </mat-radio-group>
                      @if (displayControlType === 'rs-232') {
                        <a class="madero-link u_clickable"
                          (click)="configureDisplayControl()"
                          *requireRole="'da'">
                          Configure
                        </a>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        @if (playerProService.isAutostartFlagEnabledPlayer(displayService.display)) {
          <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-start">
            <div>
              <mat-icon svgIcon="power" class="large"></mat-icon>
            </div>
            <div class="options tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div class="option-item">
                <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                  <label class="tw-mb-0" id="autostart-reboot-label">
                    Autostart after reboot
                  </label>
                  <ng-container *ngTemplateOutlet="licenseRequired" />
                  @if (!displayService.showLicenseRequired()) {
                    <mat-slide-toggle
                      aria-labelledby="autostart-reboot-label"
                      name="toggleAutostartOnBoot"
                      [(ngModel)]="displayService.display.autostartOnBoot"
                      [disabled]="displayService.showLicenseRequired()"
                      id="autostartOnBoot" />
                  }
                </div>
              </div>
            </div>
          </div>
        }
        @if (playerProService.isRebootOrRestartDailyCompatiblePlayer(displayService.display)) {
          <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-start">
            <div>
              <mat-icon svgIcon="refresh" class="large"></mat-icon>
            </div>
            <div class="options tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div class="option-item">
                <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                  <label class="tw-mb-0">
                    {{playerProService.isRebootCompatiblePlayer(displayService.display)
                  ? "Reboot" : "Restart"}} daily</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired()) {
                    <mat-slide-toggle name="toggleRestart" [(ngModel)]="displayService.display.restartEnabled" [disabled]="displayService.showLicenseRequired()" id="restartEnabled" />
                  }
                </div>
              </div>
              @if (displayService.display.restartEnabled) {
                <div class="option-item">
                  <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                    <label for="restartTime" class="tw-mb-0 text-muted">
                      {{playerProService.isRebootCompatiblePlayer(displayService.display)
                       ? 'Reboot' : 'Restart'}} at
                    </label>
                    <popup-time-picker id="restartTime" name="restartTime" ngDefaultControl [(ngModel)]="displayService.display.restartTime" [shown]="!displayService.showLicenseRequired() && displayService.display.restartEnabled"></popup-time-picker>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        @if (!playerProService.isAvocorDisplay(displayService.display)) {
          <div class="options-group tw-flex tw-flex-row tw-mb-2 tw-gap-4 tw-items-start">
            <div>
              <mat-icon svgIcon="activity" class="large"></mat-icon>
            </div>
            <div class="options tw-w-full tw-flex tw-flex-col tw-gap-4">
              <div class="option-item">
                <div class="control tw-flex tw-flex-row tw-items-center tw-justify-between">
                  <label class="tw-mb-0">
                    Monitor Activity
                  </label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired() && isMonitoringAvailable) {
                    <mat-slide-toggle
                      name="toggleMonitoring"
                      [(ngModel)]="displayService.display.monitoringEnabled"
                      (change)="trackDisplayMonitoring()"
                      [disabled]="displayService.showLicenseRequired()" />
                  }
                  @if (!displayService.showLicenseRequired() && !isMonitoringAvailable) {
                    <a href="#"
                      class="madero-link"
                      (click)="promptUpgrade('monitoring')"
                      >Plan Upgrade Required
                    </a>
                  }
                </div>
                <div class="info text-muted mt-2">
                  Alert me by email when this display goes offline.
                </div>
              </div>
              <div
                class="option-item"
                [shown]="isMonitoringEnabledAndAvailable"
              >
                <div class="control tw-flex tw-flex-row">
                  <!-- TODO: update timeline component -->
                  <timeline-basic-textbox
                    [(timelineString)]="displayService.display.monitoringSchedule"
                    [disabled]="!displayService.display.monitoringEnabled" />
                </div>
              </div>
              <div class="option-item"
                [shown]="isMonitoringEnabledAndAvailable"
                >
                <div class="control">
                  <label class="tw-mb-0 tw-font-bold">Recipients</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                </div>
                @if (!displayService.showLicenseRequired()) {
                  <div class="info tw-mt-2 tw-text-muted">Separate multiple recipients by a comma.</div>
                  <div class="tw-flex tw-flex-row tw-mt-3" [ngClass]="{ 'has-error': monitoringEmails.errors?.emails }">
                    <emails-field #monitoringEmails="ngModel" name="monitoringEmails" ngDefaultControl [(ngModel)]="displayService.display.monitoringEmails"></emails-field>
                  </div>
                  <div>
                    <span class="help-block text-danger" [shown]="monitoringEmails.errors?.emails">
                      A valid email address is required.
                    </span>
                    <span class="help-block text-danger" [shown]="monitoringEmails.errors?.duplicateEmails">
                      The email already exists.
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </mat-tab>
    @if (isScreenSharingVisible) {
      <mat-tab>
        <ng-template mat-tab-label aria-label="Screen Sharing Settings">
          <span>Screen Sharing</span>
          @if (!isScreenSharingAvailable) {
            <streamline-icon name="circleArrowUp" class="rise-blue u_lh-1 tw-ml-3" width="16" height="16"></streamline-icon>
          }
        </ng-template>
        @if (isScreenSharingAvailable) {
          <div class="fields-container options-group !tw-w-full">
            <edit-screen-sharing [model]="displayService.display" [licenseRequired]="licenseRequired" [isLicenseRequired]="displayService.showLicenseRequired()" />
          </div>
        }
      </mat-tab>
    }
    <mat-tab label="Device Info">
      <div class="field-container tw-w-full">
        <div class="options-group">
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.playerLocalIpAddress">
            <label class="tw-mb-0">IP Address</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
            @if (!displayService.showLicenseRequired()) {
              <span>
                {{displayService.display.playerLocalIpAddress}}
              </span>
            }
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.macAddress">
            <label class="tw-mb-0">MAC Address</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
            @if (!displayService.showLicenseRequired()) {
              <span>
                {{displayService.display.macAddress}}
              </span>
            }
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.os">
            <label class="tw-mb-0">Operating System</label>
            <span>
              {{displayService.display.os}}
              {{playerProService.isFireOSPlayer(displayService.display) ? " (Fire OS)" : ""}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6">
            <label class="tw-mb-0">Resolution</label>
            <span>
              <span [shown]="displayService.display.playerVersion">{{ getResolution() }}</span>
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.playerName || displayService.display.playerVersion">
            <label class="tw-mb-0 aligner-item--top">Player</label>
            <span>
              <span [shown]="displayService.display.playerName">{{displayService.display.playerName}}</span>
              <span [shown]="displayService.display.playerVersion">&nbsp;{{displayService.display.playerVersion}}</span>
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.viewerVersion && displayService.display.viewerVersion !== displayService.display.playerVersion">
            <label class="tw-mb-0">Viewer</label>
            <span>
              {{displayService.display.viewerVersion}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.browserVersion">
            <label class="tw-mb-0">Browser</label>
            <span>
              {{(displayService.display.browserName ? displayService.display.browserName + " " : "") + (displayService.display.browserVersion ? displayService.display.browserVersion : "")}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.serialNumber">
            <label class="tw-mb-0">Serial Number</label>
            <span>
              {{displayService.display.serialNumber}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.cpu">
            <label class="tw-mb-0">CPU</label>
            <span>
              {{displayService.display.cpu}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.hostName">
            <label class="tw-mb-0">Host Name</label>
            <span>
              {{displayService.display.hostName}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.manufacturer" *requireRole="'sa'">
            <label class="tw-mb-0">Manufacturer</label>
            <span>
              {{displayService.display.manufacturer}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.buildType" *requireRole="'sa'">
            <label class="tw-mb-0">Build Type</label>
            <span>
              {{displayService.display.buildType}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.showProductName()">
            <label class="tw-mb-0">Product Name</label>
            <span>
              {{displayService.display.productName}}
            </span>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-6" [shown]="displayService.display.deviceManagementId">
            <label class="tw-mb-0">Device Management ID</label>
            <span>
              {{displayService.display.deviceManagementId}}
            </span>
          </div>
          @if (screenId) {
            <div class="tw-flex tw-justify-between tw-mb-6">
              <label class="tw-mb-0 tw-mr-2">Advertising Screen ID</label>
              <span class="tw-text-right">
                {{screenId}}
              </span>
            </div>
          }
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div id="errorBox" [shown]="displayService.apiError" class="madero-style alert alert-danger tw-mt-4" role="alert">
  <strong>{{displayService.errorMessage}}</strong> {{displayService.apiError}}
</div>
